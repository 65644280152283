<app-swiper
	#swiper
	(left)="left()"
	(right)="right()"
	[leftDisable]="task.status == TaskStatus.inProgress && !task.canBeCompleted"
	[rightDisable]="task.status == TaskStatus.notStarted"
	[leftColor]="task.status == TaskStatus.inProgress ? '#00a300' : '#0095ff'"
	[rightColor]="task.status == TaskStatus.inProgress ? '#006395' : '#006395'"
	[leftIcon]="task.status == TaskStatus.inProgress ? 'done' : 'play_arrow'"
	[rightIcon]="task.status == TaskStatus.inProgress ? 'play_arrow' : 'done'"
	[disabled]="
		!(
			task.canBeCompleted &&
			(task.status == TaskStatus.inProgress ||
				task.status == TaskStatus.notStarted)
		) || !task.canBeOpened
	">
	<div
		class="all"
		[class.pointer]="pointer"
		[class.disabled]="state == TaskStatus.disabled"
		[class.completed]="state == TaskStatus.completed">
		<div
			class="icon"
			style="padding-top: 0.65rem; align-items: start"
			[ngClass]="{
				'icon-in-progress': task.status == TaskStatus.inProgress,
				'icon-not-started': task.status == TaskStatus.notStarted,
				'icon-disabled': task.status == TaskStatus.disabled,
				'icon-completed': task.status == TaskStatus.completed,
				'icon-canceled': task.status == TaskStatus.cancelled
			}">
			<mat-icon
				class="icon-in-progress"
				[ngClass]="{
					'icon-in-progress': task.status == TaskStatus.inProgress,
					'icon-not-started': task.status == TaskStatus.notStarted,
					'icon-disabled': task.status == TaskStatus.disabled,
					'icon-completed': task.status == TaskStatus.completed,
					'icon-canceled': task.status == TaskStatus.cancelled
				}">
				<!-- {{
						task.taskType == TaskType.decider
							? "signpost"
							: task.taskType == TaskType.milestone
							? "beenhere"
							: "task"
					}} -->
				{{
					task.status == TaskStatus.completed
						? "check_box"
						: "check_box_outline_blank"
				}}
			</mat-icon>

			<mat-icon
				class="icon-lock"
				matTooltip="You can not open this task as you have no legitimate reason to see the details.  You can see what state the task is in and when it will likely be completed."
				*ngIf="!pointer"
				>lock_person</mat-icon
			>
		</div>
		<div
			class="bar"
			[ngClass]="{
				'bar-in-progress': task.status == TaskStatus.inProgress,
				'bar-not-started': task.status == TaskStatus.notStarted,
				'bar-disabled': task.status == TaskStatus.disabled,
				'bar-completed': task.status == TaskStatus.completed,
				'bar-canceled': task.status == TaskStatus.cancelled
			}"></div>
		<div
			style="
				margin-left: 1rem;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
			">
			<div class="top-line">
				<div class="title">
					{{ task.privetTitle || task.title }}

					<span
						*ngIf="task.late && (task.children?.length || 0) == 0"
						class="late-child"
						>Over-Due</span
					>
					<span
						*ngIf="
							task.status == TaskStatus.completed &&
							task.taskType == TaskType.decider &&
							task.defaultOption !== task.result
						">
						&nbsp;=> {{ taskResultDetails(task) }}</span
					>
				</div>
				<div class="issues">
					<mat-icon
						color="warn"
						*ngIf="
							task.status == TaskStatus.completed &&
							task.taskType == TaskType.decider &&
							task.defaultOption !== task.result
						"
						>warning_amber</mat-icon
					>
				</div>
			</div>
			<div class="bottom-line">
				<div
					class="description"
					[class.over-due]="
						(task.children?.length || 0) == 0 &&
						(task.status == TaskStatus.inProgress ||
							task.status == TaskStatus.notStarted) &&
						task.estimatedCompletionDate &&
						!isDateInTheFuture(task.estimatedCompletionDate)
					">
					{{
						task.status == TaskStatus.inProgress
							? "In Progress"
							: task.status == TaskStatus.notStarted
							? "To Do"
							: task.status == TaskStatus.disabled
							? "Blocked"
							: task.status == TaskStatus.completed
							? "Completed"
							: task.status == TaskStatus.cancelled
							? "Canceled"
							: ""
					}}
					<mat-icon
						class="icon-disabled"
						*ngIf="task.status == TaskStatus.disabled"
						style="height: 1rem; width: 1rem; font-size: 1rem"
						>lock</mat-icon
					>

					<span
						*ngIf="
							(task.status == TaskStatus.inProgress ||
								task.status == TaskStatus.notStarted) &&
							task.estimatedCompletionDate &&
							isDateInTheFuture(task.estimatedCompletionDate)
						"
						style="font-weight: 300; margin: 0 0.25rem"
						>should be completed
						{{ getTimeUntilDate(task.estimatedCompletionDate) }}</span
					>
					<span
						*ngIf="
							(task.status == TaskStatus.inProgress ||
								task.status == TaskStatus.notStarted) &&
							task.estimatedCompletionDate &&
							!isDateInTheFuture(task.estimatedCompletionDate)
						"
						style="font-weight: 300; margin: 0 0.25rem"
						>should have been completed
						{{ getTimeUntilDate(task.estimatedCompletionDate) }}</span
					>

					<mat-icon
						class="info-button"
						[matTooltip]="stateTooltip()"
						#infoTooltip="matTooltip"
						(click)="infoTooltip.toggle(); $event.stopPropagation()"
						>info</mat-icon
					>
					<!-- <span> {{ task.estimatedCompletionDate | date }} </span>
					<span> {{ task.estimatedBizDaysToComplete }} </span> -->
					<!-- <span> {{ task.order || "none" }} </span> -->
				</div>
			</div>
			<div style="min-height: 0.7rem" *ngIf="(task.children?.length || 0) > 0">
				<app-line-progress
					[showPercent]="false"
					[completed]="
						(task.childrenProgress?.completed?.workDaysCompleted || 0) +
						(task.childrenProgress?.inProgress?.workDaysCompleted || 0) +
						(task.childrenProgress?.notStarted?.workDaysCompleted || 0)
					"
					[inProgress]="
						(task.childrenProgress?.inProgress?.totalWorkDays || 0) -
						(task.childrenProgress?.inProgress?.workDaysCompleted || 0)
					"
					[notStarted]="
						(task.childrenProgress?.notStarted?.totalWorkDays || 0) -
						(task.childrenProgress?.notStarted?.workDaysCompleted || 0)
					"
					[disabled]="
						task.childrenProgress?.disabled?.totalWorkDays || 0
					"></app-line-progress>
				<div
					style="margin-left: 1rem; margin-top: 0.25rem; font-size: 0.7rem"
					*ngIf="
						(task.children?.length || 0) > 0 &&
						!showSubs.has(task.id || '') &&
						(task.status == TaskStatus.completed ||
							task.status == TaskStatus.disabled)
					">
					<span
						style="text-decoration: none"
						class="hov-button"
						(click)="showSubTasks($event, task.id || '')">
						{{ task.children?.length }} Sub-Tasks</span
					>
				</div>
				<div
					style="margin-left: 1rem; margin-top: 0.25rem"
					*ngIf="
						(task.children?.length || 0) > 0 &&
						(task.status == TaskStatus.notStarted ||
							task.status == TaskStatus.inProgress ||
							showSubs.has(task.id || ''))
					">
					<div
						*ngFor="let child of task.children; let i = index"
						style="font-size: 0.7rem">
						<div
							(click)="openTask($event, child)"
							style="display: flex"
							class="child"
							[class.hov-button]="child.canBeOpened"
							[class.disabled-child]="child.status == TaskStatus.disabled"
							[class.not-started-child]="child.status == TaskStatus.notStarted"
							[class.in-progress-child]="child.status == TaskStatus.inProgress"
							[class.completed-child]="child.status == TaskStatus.completed">
							<ng-container *ngIf="child.status != TaskStatus.cancelled">
								<mat-icon [inline]="false" class="child-icon">{{
									child.status == TaskStatus.completed
										? "check_box"
										: "check_box_outline_blank"
								}}</mat-icon>
								<app-strike-line
									[isStriked]="child.status == TaskStatus.completed"
									>{{ child.title }}</app-strike-line
								>
								<span
									*ngIf="child.late && (child.children?.length || 0) == 0"
									class="late-child"
									>Over-Due</span
								>
								<span style="flex-grow: 1"></span>
								<button
									*ngIf="isAgent"
									class="small-icon-button"
									mat-icon-button
									[matMenuTriggerFor]="menu"
									(click)="preventDefault($event)">
									<mat-icon>more_vert</mat-icon>
								</button>
								<mat-menu #menu="matMenu">
									<button
										mat-menu-item
										[disabled]="child.status == TaskStatus.notStarted"
										(click)="
											changeSubTaskStatus(
												$event,
												child,
												TaskStatus.notStarted,
												menu
											)
										">
										ToDo
									</button>
									<button
										mat-menu-item
										[disabled]="child.status == TaskStatus.inProgress"
										(click)="
											changeSubTaskStatus(
												$event,
												child,
												TaskStatus.inProgress,
												menu
											)
										">
										In Progress
									</button>
									<button
										mat-menu-item
										[disabled]="child.status == TaskStatus.completed"
										(click)="
											changeSubTaskStatus(
												$event,
												child,
												TaskStatus.completed,
												menu
											)
										">
										Completed
									</button>
									<button
										mat-menu-item
										(click)="
											changeSubTaskStatus(
												$event,
												child,
												TaskStatus.cancelled,
												menu
											)
										">
										Canceled
									</button>
									<button
										mat-menu-item
										[disabled]="
											child.status == TaskStatus.completed ||
											child.status == TaskStatus.disabled ||
											child.hasChildren
										"
										(click)="updateTaskEta($event, child, menu)">
										Set Due Date
									</button>
								</mat-menu>
							</ng-container>
						</div>
						<div
							style="
								margin-left: 1rem;
								font-size: 0.5rem;
								text-decoration: none;
							"
							class="disabled-child"
							*ngIf="
								(child.children?.length || 0) > 0 &&
								!showSubs.has(child.id || '') &&
								(child.status == TaskStatus.completed ||
									child.status == TaskStatus.disabled)
							">
							<span
								style="text-decoration: none"
								class="hov-button"
								(click)="showSubTasks($event, child.id || '')">
								{{ child.children?.length }} Sub-Tasks</span
							>
						</div>
						<div
							style="margin-left: 1rem"
							*ngIf="
								(child.children?.length || 0) > 0 &&
								(child.status == TaskStatus.notStarted ||
									child.status == TaskStatus.inProgress ||
									showSubs.has(child.id || ''))
							">
							<div
								*ngFor="let subChild of child.children; let i = index"
								style="font-size: 0.5rem; display: flex"
								class="child"
								(click)="openTask($event, subChild)"
								[class.hov-button]="subChild.canBeOpened"
								[class.disabled-child]="subChild.status == TaskStatus.disabled"
								[class.not-started-child]="
									subChild.status == TaskStatus.notStarted
								"
								[class.in-progress-child]="
									subChild.status == TaskStatus.inProgress
								"
								[class.completed-child]="
									subChild.status == TaskStatus.completed
								">
								<ng-container *ngIf="subChild.status != TaskStatus.cancelled">
									<mat-icon [inline]="false" class="child-icon">{{
										subChild.status == TaskStatus.completed
											? "check_box"
											: "check_box_outline_blank"
									}}</mat-icon>
									<app-strike-line
										[isStriked]="subChild.status == TaskStatus.completed"
										>{{ subChild.title }}</app-strike-line
									>
									<span *ngIf="subChild.late" class="late-sub-child"
										>Over-Due</span
									>
									<span style="flex-grow: 1"></span>
									<button
										*ngIf="isAgent"
										class="small-icon-button"
										mat-icon-button
										[matMenuTriggerFor]="menu"
										(click)="preventDefault($event)">
										<mat-icon>more_vert</mat-icon>
									</button>
									<mat-menu #menu="matMenu">
										<button
											mat-menu-item
											[disabled]="child.status == TaskStatus.notStarted"
											(click)="
												changeSubTaskStatus(
													$event,
													child,
													TaskStatus.notStarted,
													menu
												)
											">
											ToDo
										</button>
										<button
											mat-menu-item
											[disabled]="child.status == TaskStatus.inProgress"
											(click)="
												changeSubTaskStatus(
													$event,
													child,
													TaskStatus.inProgress,
													menu
												)
											">
											In Progress
										</button>
										<button
											mat-menu-item
											[disabled]="child.status == TaskStatus.completed"
											(click)="
												changeSubTaskStatus(
													$event,
													child,
													TaskStatus.completed,
													menu
												)
											">
											Completed
										</button>
										<button
											mat-menu-item
											(click)="
												changeSubTaskStatus(
													$event,
													child,
													TaskStatus.cancelled,
													menu
												)
											">
											Canceled
										</button>
										<button
											mat-menu-item
											[disabled]="
												child.status == TaskStatus.completed ||
												child.status == TaskStatus.disabled ||
												child.hasChildren
											"
											(click)="updateTaskEta($event, child, menu)">
											Set Due Date
										</button>
									</mat-menu>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="chips">
			<mat-chip-listbox aria-label="Task Info" style="align-items: center">
				<mat-chip-option
					*ngIf="task.buyersTrack"
					color="primary"
					selectable="false"
					highlighted>
					<span class="hide-large"> B </span>
					<span class="hide-small"> Buyer </span>
				</mat-chip-option>
				<mat-chip-option
					*ngIf="task.sellersTrack"
					color="accent"
					selectable="false"
					style="color: #006396; background-color: #0063961a"
					highlighted>
					<span class="hide-large" style="color: #006396"> S </span>
					<span class="hide-small" style="color: #006396"> Seller </span>
				</mat-chip-option>
			</mat-chip-listbox>
			<button
				*ngIf="isAgent"
				mat-icon-button
				[matMenuTriggerFor]="menu"
				(click)="preventDefault($event)">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button
					mat-menu-item
					[disabled]="task.status == TaskStatus.notStarted"
					(click)="changeTaskStatus(TaskStatus.notStarted)">
					ToDo
				</button>
				<button
					mat-menu-item
					[disabled]="task.status == TaskStatus.inProgress"
					(click)="changeTaskStatus(TaskStatus.inProgress)">
					In Progress
				</button>
				<button
					mat-menu-item
					[disabled]="task.status == TaskStatus.completed"
					(click)="changeTaskStatus(TaskStatus.completed)">
					Completed
				</button>
				<button
					mat-menu-item
					[disabled]="task.status == TaskStatus.cancelled"
					(click)="changeTaskStatus(TaskStatus.cancelled)">
					Canceled
				</button>
				<button
					mat-menu-item
					[disabled]="
						task.status == TaskStatus.cancelled ||
						task.status == TaskStatus.completed ||
						task.status == TaskStatus.disabled ||
						task.hasChildren
					"
					(click)="updateTaskEta($event, task, menu)">
					Set Due Date
				</button>
				<button mat-menu-item (click)="reInitTask($event, task.id || '')">
					Re-Init Task
				</button>
			</mat-menu>

			<!-- <button
				class="hide-small"
				mat-icon-button
				matTooltip="Mark As Completed"
				*ngIf="
					task.canBeCompleted &&
					task.status == TaskStatus.inProgress &&
					task.canBeOpened
				"
				(click)="markAsComplete.emit($event)">
				<mat-icon>done</mat-icon>
			</button>
			<button
				class="hide-small"
				mat-icon-button
				matTooltip="Mark As In Progress"
				*ngIf="
					task.canBeCompleted &&
					task.status == TaskStatus.notStarted &&
					task.canBeOpened
				"
				(click)="markAsInprogress.emit($event)">
				<mat-icon>play_arrow</mat-icon>
			</button>
			<span
				class="hide-small"
				*ngIf="
					!task.canBeCompleted ||
					task.status == TaskStatus.completed ||
					task.status == TaskStatus.disabled ||
					task.status == TaskStatus.cancelled ||
					!task.canBeOpened
				"
				style="width: 48px"></span> -->
		</div>
		<!-- <div
			*ngIf="
				(task.children?.length || 0) > 0 && task.status == TaskStatus.inProgress
			"
			style="height: 2rem; padding: 0 0.2rem; margin-top: -1rem">
			
			<app-line-progress
				[showPercent]="false"
				[completed]="taskCompletedCount(task)"
				[inProgress]="taskInProgressCount(task)"
				[notStarted]="taskNotStartedCount(task)"
				[disabled]="taskDisabledCount(task)"></app-line-progress>
		</div> -->

		<!-- <mat-divider [class.pointer]="pointer"></mat-divider> -->
	</div>
</app-swiper>
