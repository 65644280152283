import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';

@Injectable({
	providedIn: 'root',
})
export class OrgsService {
	constructor(public backend: BackendService) {}

	async getMyOrgs() {
		let r = await this.backend.get('orgs');
		return r.data;
	}

	async getOrg(id: string) {
		const r = await this.backend.get(`org/${id}`);
		return r.data;
	}

	async patchOrg(id: string, data: Partial<Org>) {
		const r = (await this.backend.patch(`org/${id}`, data)) as any;
		return r.data;
	}

	async putOrg(org: Partial<Org>) {
		const r: any = await this.backend.put('org', org);
		return r.data;
	}

	async deleteOrg(id: string) {
		const r: any = await this.backend.delete(`org/${id}`);
		return r.data;
	}

	async putOrgRole(orgId: string, email: string, role: 'owner' | 'admin') {
		const r: any = await this.backend.put(`org/${orgId}/role`, { email, role });
		return r.data;
	}

	async deleteOrgRole(orgId: string, roleId: string) {
		const r: any = await this.backend.delete(`org/${orgId}/role/${roleId}`);
		return r.data;
	}

	async deleteBranch(orgId: string, branchId: string) {
		const r: any = await this.backend.delete(`org/${orgId}/branch/${branchId}`);
		return r.data;
	}

	async patchBranch(orgId: string, branchId: string, data: Partial<OrgBranch>) {
		const r: any = await this.backend.patch(
			`org/${orgId}/branch/${branchId}`,
			data
		);
		return r.data;
	}

	async putBranch(orgId: string, branch: Partial<OrgBranch>) {
		const r: any = await this.backend.put(`org/${orgId}/branch`, {
			...branch,
			orgId,
		});
		return r.data;
	}

	async deleteBranchRole(orgId: string, branchId: string, roleId: string) {
		const r: any = await this.backend.delete(
			`org/${orgId}/branch/${branchId}/role/${roleId}`
		);
		return r.data;
	}

	async putBranchRole(
		orgId: string,
		branchId: string,
		email: string,
		role: 'admin' | 'employee'
	) {
		return await this.backend.put(`org/${orgId}/branch/${branchId}/role`, {
			email,
			role,
		});
	}

	async getMyOrgProperties() {
		const r = await this.backend.get('org/properties');
		return r.data;
	}
}

export interface Org {
	id: string;
	name?: string;
	website?: string;
	logo?: string;
	roles?: OrgRole[];
	canEdit?: boolean;
	canDelete?: boolean;
	branches?: OrgBranch[];
}

export interface OrgRole {
	id: string;
	role: 'owner' | 'admin';
	orgId: string;
	email: string;
}

export interface OrgBranch {
	id: string;
	orgId: string;
	name: string;
	streetAddress?: string;
	postcode?: string;
	canEdit?: boolean;
	canDelete?: boolean;
	roles?: OrgBranchRole[];
}

export interface OrgBranchRole {
	id: string;
	role: 'admin' | 'employee';
	branchId: string;
	email: string;
}
