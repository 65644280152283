<div class="space-y-4">
	<p class="text-base leading-relaxed">
		Organizations in Clear Chain represent your company's complete structure.
		For example, a law firm like Smith & Morgan Law might operate multiple
		branches across London and Liverpool, while an estate agency could manage
		five different locations under one organizational umbrella.
	</p>

	<p class="text-base leading-relaxed">
		We've implemented a three-tier permission system to ensure secure
		management: • Organization owners have full control, including the ability
		to delete organizations • Administrators can manage branches and update role
		assignments • Branch employees have transaction-specific access
	</p>

	<p class="text-base leading-relaxed">
		When agents or solicitors are involved in multiple branches, they can easily
		select the relevant branch for each property transaction through a simple
		dropdown menu. This ensures accurate tracking and transparency across your
		entire organization.
	</p>
</div>
<!-- <pre>{{ myOrgs | json }}</pre> -->

<ul style="list-style: auto; margin-top: 1rem">
	<li *ngFor="let org of myOrgs">
		<span style="font-weight: 600; font-size: 1.25rem">{{ org.name }}</span>

		<button *ngIf="org.canDelete" mat-icon-button (click)="deleteOrg(org.id)">
			<mat-icon>delete</mat-icon>
		</button>
		<app-group-title title="Org Roles" *ngIf="org.canEdit">
			<ul style="list-style: none; margin-top: -1rem">
				<li *ngFor="let user of org.roles">
					{{ user.email }} - {{ user.role }}
					<button
						*ngIf="org.canDelete"
						mat-icon-button
						(click)="deleteOrgRole(org.id, user.id)">
						<mat-icon>delete</mat-icon>
					</button>
				</li>
			</ul>
			<div
				*ngIf="org.canEdit"
				style="display: flex; gap: 1rem; flex-wrap: wrap; margin-top: 1rem">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="flex-grow: 1">
					<mat-label> User Email</mat-label>
					<input #newUserEmail matInput placeholder="Email" />
				</mat-form-field>
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="flex-grow: 1">
					<mat-label> User Role</mat-label>
					<mat-select #newUserRole>
						<mat-option value="admin">Admin</mat-option>
						<mat-option value="owner">Owner</mat-option>
					</mat-select>
				</mat-form-field>
				<button
					mat-raised-button
					color="primary"
					(click)="
						newOrgRole(org.id, newUserEmail.value, newUserRole.value);
						newUserEmail.value = '';
						newUserRole.value = ''
					">
					Add
				</button>
			</div>
		</app-group-title>

		<app-group-title title="Org Branches">
			<ul style="list-style: auto; margin-top: -1rem">
				<li *ngFor="let branch of org.branches">
					{{ branch.name }}
					<button
						*ngIf="branch.canDelete"
						mat-icon-button
						(click)="deleteBranch(org.id, branch.id)">
						<mat-icon>delete</mat-icon>
					</button>

					<app-group-title title="Branch Roles" *ngIf="branch.canEdit">
						<ul style="list-style: none; margin-top: -1rem">
							<li *ngFor="let user of branch.roles">
								{{ user.email }} - {{ user.role }}
								<button
									*ngIf="branch.canEdit"
									mat-icon-button
									(click)="deleteBranchRole(org.id, branch.id, user.id)">
									<mat-icon>delete</mat-icon>
								</button>
							</li>
						</ul>
						<div
							*ngIf="branch.canEdit"
							style="
								display: flex;
								gap: 1rem;
								flex-wrap: wrap;
								margin-top: 1rem;
							">
							<mat-form-field
								appearance="outline"
								floatLabel="always"
								style="flex-grow: 1">
								<mat-label> User Email</mat-label>
								<input #newBranchUserEmail matInput placeholder="Email" />
							</mat-form-field>
							<mat-form-field
								appearance="outline"
								floatLabel="always"
								style="flex-grow: 1">
								<mat-label> User Role</mat-label>
								<mat-select #newBranchUserRole>
									<mat-option value="admin">Admin</mat-option>
									<mat-option value="employee">Employee</mat-option>
								</mat-select>
							</mat-form-field>
							<button
								mat-raised-button
								color="primary"
								(click)="
									newBranchRole(
										org.id,
										branch.id,
										newBranchUserEmail.value,
										newBranchUserRole.value
									);
									newBranchUserEmail.value = '';
									newBranchUserRole.value = ''
								">
								Add
							</button>
						</div>
					</app-group-title>
				</li>
			</ul>
			<div
				*ngIf="org.canEdit"
				style="display: flex; gap: 1rem; flex-wrap: wrap">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="flex-grow: 1">
					<mat-label>New Branch Name</mat-label>
					<input #newBranchName matInput placeholder="Name" />
				</mat-form-field>
				<button
					mat-raised-button
					color="primary"
					(click)="
						newBranch(org.id, newBranchName.value); newBranchName.value = ''
					">
					Add
				</button>
			</div>
		</app-group-title>
	</li>
</ul>

<div style="display: flex; gap: 1rem; flex-wrap: wrap; margin-top: 1rem">
	<mat-form-field appearance="outline" floatLabel="always" style="flex-grow: 1">
		<mat-label>New Org Name</mat-label>
		<input #newName matInput placeholder="Name" />
	</mat-form-field>
	<button
		mat-raised-button
		color="primary"
		(click)="newOrg(newName.value); newName.value = ''">
		Add
	</button>
</div>
