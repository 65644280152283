import {
	ChangeDetectionStrategy,
	Component,
	inject,
	ViewChild,
	type OnInit,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrgsService } from '../services/orgs.service';
import {
	PropertiesBackendService,
	Property,
} from '../services/properties-backend.service';
import { Pipe, PipeTransform } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

interface PipelineProperty extends Omit<Property, 'offerDate' | 'createdAt'> {
	offerDate: Date | undefined;
	createdAt: Date | undefined;
	daysOnMarket: number;
	psf: number | undefined;
	fee: number | undefined;
	percentageOfAsking: number | undefined;
	canView: boolean;
}

@Component({
	selector: 'app-pipeline',

	templateUrl: './pipeline.component.html',
	styleUrls: ['./pipeline.component.css'],
})
export class PipelineComponent implements OnInit {
	orgsService = inject(OrgsService);
	propertiesService = inject(PropertiesBackendService);
	snackBar = inject(MatSnackBar);
	properties: Property[] = [];
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild(MatTable) table!: MatTable<Property>;

	displayedColumns: string[] = [
		'addressline1',
		'holdUp',
		'daysOnMarket',
		'sqft',
		'psf',
		'offerDate',
		'askingPrice',
		'agreedPrice',
		'percentageOfAsking',
		'feePercentage',
		'fee',
		'completionDate',
	];
	dataSource = new MatTableDataSource(this.marshalProperties(this.properties));

	async ngOnInit() {
		await this.getProperties();
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;

		this.sort.sort({
			id: 'completionDate',
			start: 'asc',
			disableClear: false,
		});
	}

	async getProperties() {
		this.properties = await this.orgsService.getMyOrgProperties();
		this.dataSource.data = this.marshalProperties(this.properties);
	}

	private marshalProperties(properties: Property[]): PipelineProperty[] {
		return properties.map((p) => this.marshalProperty(p));
	}

	private marshalProperty(p: Property): PipelineProperty {
		const agreedPrice = p.agreedPrice
			? parseFloat(p.agreedPrice.toString().replace(/,/g, ''))
			: undefined;
		const askingPrice = p.askingPrice
			? parseFloat(p.askingPrice.toString().replace(/,/g, ''))
			: undefined;
		const offerDate = p.offerDate ? new Date(p.offerDate) : undefined;
		const createdAt = p.createdAt ? new Date(p.createdAt) : undefined;
		const daysOnMarket = this.datesBetween(
			new Date(p.offerDate || new Date()),
			new Date(p.createdAt || new Date())
		);
		let psf = 0;
		if (p.sqft && askingPrice) psf = askingPrice / p.sqft;
		if (p.sqft && agreedPrice) psf = agreedPrice / p.sqft;

		let fee = 0;
		if (askingPrice && p.feePercentage)
			fee = askingPrice * (p.feePercentage / 100);
		if (agreedPrice && p.feePercentage)
			fee = agreedPrice * (p.feePercentage / 100);

		const percentageOfAsking =
			askingPrice && agreedPrice
				? (agreedPrice / askingPrice) * 100
				: undefined;

		const canView = (p.securityTypes?.length || 0) > 0;
		return {
			...p,
			agreedPrice,
			askingPrice,
			offerDate,
			createdAt,
			daysOnMarket,
			psf,
			fee,
			percentageOfAsking,
			canView,
		};
	}

	// dates Between means that any date time on march first and march second will return 1
	// this is because the difference between the two dates is 1 day
	private datesBetween(d1: Date, d2: Date): number {
		const dateOnly1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
		const dateOnly2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
		return Math.floor(
			(dateOnly2.getTime() - dateOnly1.getTime()) / (1000 * 60 * 60 * 24)
		);
	}

	editModeMap = new Map<string, Set<string>>();
	inEditMode(propertyId: string, column: string): boolean {
		const set = this.editModeMap.get(propertyId);
		return set ? set.has(column) : false;
	}

	setEditMode(propertyId: string, column: string) {
		const set = this.editModeMap.get(propertyId);
		if (set) set.add(column);
		else this.editModeMap.set(propertyId, new Set([column]));
	}

	async unsetEditMode(propertyId: string, column: string, $event: any) {
		const set = this.editModeMap.get(propertyId);
		if (set) set.delete(column);
		console.log($event.target.value);
		let val = $event.target.value;
		if (column === 'askingPrice' || column === 'agreedPrice') {
			val = parseFloat(val.replace(/,/g, ''));
		} else val = parseFloat(val);
		const sBar = this.snackBar.open('Updating property...', 'Close', {
			duration: 5000,
			verticalPosition: 'top',
		});
		await this.propertiesService.patch(propertyId, {
			id: propertyId,
			[column]: val,
		} as any);
		await this.getProperties();
		sBar.dismiss();
	}
}

//money gbp pipe
@Pipe({
	name: 'gbp',
})
export class GbpPipe implements PipeTransform {
	transform(value: number | undefined): string {
		if (value === undefined) return '';
		return new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(value);
	}
}
