import { Injectable } from '@angular/core';
import { backendResponse, BackendService } from './backend.service';
import { linkedPerson, TaskResult, TaskStatus } from './task-backend.service';

@Injectable({
	providedIn: 'root',
})
export class PropertiesBackendService {
	constructor(public backend: BackendService) {}

	async getOne(propertyId: string): Promise<backendResponse<Property>> {
		return (await this.backend.get(
			`property/${propertyId}`
		)) as backendResponse<Property>;
	}

	async getList(options?: {
		limit?: number;
	}): Promise<backendResponse<Property[]>> {
		let query = ``;
		if (options?.limit) {
			if (query == ``) query += `?`;
			else query += `&`;
			query += `limit=${options.limit}`;
		}

		return (await this.backend.get(`properties${query}`)) as backendResponse<
			Property[]
		>;
	}

	async patch(
		propertyId: string,
		body: Property
	): Promise<backendResponse<Property>> {
		return (await this.backend.patch(
			`property/${propertyId}`,
			body
		)) as backendResponse<Property>;
	}

	async put(body: Property): Promise<backendResponse<Property>> {
		return (await this.backend.put(
			`property`,
			body
		)) as backendResponse<Property>;
	}

	async delete(propertyId: string) {
		return (await this.backend.delete(
			`property/${propertyId}`
		)) as backendResponse<Property>;
	}

	async getNicknames(
		propertyId: string
	): Promise<backendResponse<{ name: string; cats: string[] }[]>> {
		return (await this.backend.get(
			`property/${propertyId}/nicknames`
		)) as backendResponse<{ name: string; cats: string[] }[]>;
	}

	async getCount(): Promise<backendResponse<number>> {
		return (await this.backend.get(
			`properties/count`
		)) as backendResponse<number>;
	}

	async checkIfInuse(UPRN: string): Promise<
		backendResponse<
			| boolean
			| {
					url: string;
					hasBuyer: boolean;
					hasSeller: boolean;
					chainId: string;
			  }
		>
	> {
		return (await this.backend.get(
			`property/inuse?UPRN=${encodeURIComponent(UPRN)}`
		)) as backendResponse<
			| boolean
			| {
					url: string;
					hasBuyer: boolean;
					hasSeller: boolean;
					chainId: string;
			  }
		>;
	}
}

export enum activeType {
	active = 'y',
}
export interface Property {
	id: string;
	linkType: 'property';
	address?: string;
	addressline1?: string;
	addressline2?: string;
	number?: string;
	premise?: string;
	street?: string;
	posttown?: string;
	county?: string;
	latitude?: string;
	longitude?: string;
	usrn?: string;
	nickName?: string;
	postCode?: string;
	UPRN?: string;
	isPlaceHolder?: boolean;
	agreedPrice?: number;
	askingPrice?: number;
	feePercentage?: number;
	sqft?: number;
	offerDate?: number;
	chainId: string;
	buyersId?: string;
	sellersId?: string;
	userCreated?: string;
	isLate: boolean;
	active?: activeType | undefined;
	persons: linkedPerson[];
	sellerPersons: linkedPerson[];
	buyerPersons: linkedPerson[];
	tasks?: {
		id: string;
		title: string;
		status?: TaskStatus;
		result?: TaskResult;
		issueCount?: number;
	}[];
	offers?: {
		id: string;
		email: string;
		firstName: string;
		lastName: string;
		status:
			| 'unsent'
			| 'sent'
			| 'bounced'
			| 'opened'
			| 'submitted'
			| 'accepted'
			| 'rejected';
		lastOpened?: number;
		submitted?: number;
		summery?: string;
	}[];

	issueCount?: number;
	titles?: {
		number: string;
		tenure: string;
		ownerShipType: number;
		documents: {
			id: string;
			name: string;
			date: number;
			type: string;
			taskId: string;
		}[];
	}[];
	postLinks?: {
		url: string;
	}[];
	pics?: {
		linkUrl: string;
		ogUrl: string;
		id: string;
	}[];
	pic?: string;
	sanPic?: string;

	postedMaterialId?: string;
	savedMaterialId?: string;
	securityTypes?: string[];

	agentOrgId?: string;
	agentBranchId?: string;

	sellingConveyancerOrgId?: string;
	sellingConveyancerBranchId?: string;

	buyingConveyancerOrgId?: string;
	buyingConveyancerBranchId?: string;
	createdAt?: number;
	completionDate?: number;
}
