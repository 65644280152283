<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
	<!-- Position Column -->
	<ng-container matColumnDef="addressline1">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Address">
			Property
		</th>
		<td mat-cell *matCellDef="let element">
			<a
				*ngIf="element.canView"
				[routerLink]="['/chain', element.chainId, 'property', element.id]"
				>{{ element.addressline1 }}</a
			>
			<span *ngIf="!element.canView">{{ element.addressline1 }}</span>
		</td>
	</ng-container>

	<ng-container matColumnDef="holdUp">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Hold Up">
			The Hold Up
		</th>
		<td mat-cell *matCellDef="let element" style="max-width: 35rem">
			{{ element.holdUp }}
		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="daysOnMarket">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Days On The Market">
			Days On The Market
		</th>
		<td mat-cell *matCellDef="let element">{{ element.daysOnMarket }}</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="sqft">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by sqft">
			Sqft
		</th>
		<td
			mat-cell
			*matCellDef="let element"
			(dblclick)="setEditMode(element.id, 'sqft')">
			<span *ngIf="!inEditMode(element.id, 'sqft')">{{ element.sqft }}</span
			><input
				*ngIf="inEditMode(element.id, 'sqft')"
				(blur)="unsetEditMode(element.id, 'sqft', $event)"
				type="number"
				style="width: 100%"
				[value]="element.sqft" />
		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="psf">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Cost Per sqft">
			Cost/sqft
		</th>
		<td mat-cell *matCellDef="let element">{{ element.psf | gbp }}/sqft</td>
	</ng-container>

	<!-- Weight Column -->
	<ng-container matColumnDef="offerDate">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Offer Date">
			Date Of Offer
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.offerDate | date : "dd/MM/yy" }}
		</td>
	</ng-container>

	<!-- Symbol Column -->
	<ng-container matColumnDef="askingPrice">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Asking Price">
			Asking Price
		</th>
		<td
			mat-cell
			*matCellDef="let element"
			(dblclick)="setEditMode(element.id, 'askingPrice')">
			<span *ngIf="!inEditMode(element.id, 'askingPrice')">{{
				element.askingPrice | gbp
			}}</span>
			<input
				*ngIf="inEditMode(element.id, 'askingPrice')"
				(blur)="unsetEditMode(element.id, 'askingPrice', $event)"
				type="number"
				style="width: 100%"
				[value]="element.askingPrice" />
		</td>
	</ng-container>

	<ng-container matColumnDef="agreedPrice">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Agreed Price">
			Agreed Price
		</th>
		<td
			mat-cell
			*matCellDef="let element"
			(dblclick)="setEditMode(element.id, 'agreedPrice')">
			<span *ngIf="!inEditMode(element.id, 'agreedPrice')">{{
				element.agreedPrice | gbp
			}}</span>
			<input
				*ngIf="inEditMode(element.id, 'agreedPrice')"
				(blur)="unsetEditMode(element.id, 'agreedPrice', $event)"
				type="number"
				style="width: 100%"
				[value]="element.agreedPrice" />
		</td>
	</ng-container>

	<ng-container matColumnDef="percentageOfAsking">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Percentage Of Asking">
			% Of Asking
		</th>
		<td mat-cell *matCellDef="let element">
			{{ element.percentageOfAsking | number : "1.1-1" }}%
		</td>
	</ng-container>

	<ng-container matColumnDef="feePercentage">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Fee Percentage">
			Fee %
		</th>
		<td
			mat-cell
			*matCellDef="let element"
			(dblclick)="setEditMode(element.id, 'feePercentage')">
			<span *ngIf="!inEditMode(element.id, 'feePercentage')"
				>{{ element.feePercentage | number : "1.2-2" }}%</span
			>
			<input
				*ngIf="inEditMode(element.id, 'feePercentage')"
				(blur)="unsetEditMode(element.id, 'feePercentage', $event)"
				type="number"
				style="width: 100%"
				[value]="element.feePercentage" />
		</td>
	</ng-container>

	<ng-container matColumnDef="fee">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by fee">
			Fee
		</th>
		<td mat-cell *matCellDef="let element">{{ element.fee | gbp }}</td>
	</ng-container>

	<ng-container matColumnDef="completionDate">
		<th
			mat-header-cell
			*matHeaderCellDef
			mat-sort-header
			sortActionDescription="Sort by Completion Date">
			Completion Date
		</th>
		<td mat-cell *matCellDef="let element">
			<span *ngIf="!!element.completionDate">{{
				element.completionDate | date : "dd/MM/yy"
			}}</span>
			<span
				*ngIf="!element.completionDate && !!element.estimatedCompletionDate"
				>{{ element.estimatedCompletionDate | date : "dd/MM/yy" }}</span
			>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr
		mat-row
		*matRowDef="let row; columns: displayedColumns"
		[class.completed]="!!row.completionDate"></tr>
</table>
