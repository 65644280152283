import { Component, inject, type OnInit } from '@angular/core';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { Org, OrgsService } from 'src/app/services/orgs.service';

@Component({
	selector: 'app-my-orgs',

	templateUrl: './my-orgs.component.html',
	styleUrls: ['./my-orgs.component.css'],
})
export class MyOrgsComponent implements OnInit {
	myOrgs: Org[] = [];
	confirmService = inject(ConfirmDialogService);
	constructor(public orgsBackend: OrgsService) {}
	async ngOnInit() {
		await this.getMyOrgs();
	}

	async getMyOrgs() {
		this.myOrgs = await this.orgsBackend.getMyOrgs();
	}

	async newOrg(name: string) {
		await this.orgsBackend.putOrg({ name });
		await this.getMyOrgs();
	}

	async newBranch(orgId: string, name: string) {
		await this.orgsBackend.putBranch(orgId, { name });
		await this.getMyOrgs();
	}

	async deleteOrg(orgId: string) {
		const org = this.myOrgs.find((o) => o.id == orgId);
		const orgName = org?.name || 'this';
		if (
			await this.confirmService.confirm({
				message: `Are you sure you want to delete the ${orgName} org?`,
			})
		) {
			await this.orgsBackend.deleteOrg(orgId);
			await this.getMyOrgs();
		}
	}

	async deleteBranch(orgId: string, branchId: string) {
		const org = this.myOrgs.find((o) => o.id == orgId);
		const branch = org?.branches?.find((b) => b.id == branchId);
		const branchName = branch?.name || 'this';
		if (
			await this.confirmService.confirm({
				message: `Are you sure you want to delete ${branchName} branch?`,
			})
		) {
			await this.orgsBackend.deleteBranch(orgId, branchId);
			await this.getMyOrgs();
		}
	}

	async deleteOrgRole(orgId: string, roleId: string) {
		const org = this.myOrgs.find((o) => o.id == orgId);
		const role = org?.roles?.find((r) => r.id == roleId);
		const roleName = role
			? `${role.email} as ${role.role} for ${org?.name}`
			: 'this';

		if (
			await this.confirmService.confirm({
				message: `Are you sure you want to delete ${roleName} role?`,
			})
		) {
			await this.orgsBackend.deleteOrgRole(orgId, roleId);
			await this.getMyOrgs();
		}
	}

	async deleteBranchRole(orgId: string, branchId: string, roleId: string) {
		const org = this.myOrgs.find((o) => o.id == orgId);
		const branch = org?.branches?.find((b) => b.id == branchId);
		const role = branch?.roles?.find((r) => r.id == roleId);
		const roleName = role
			? `${role.email} as ${role.role} for ${branch?.name} branch`
			: 'this';

		if (
			await this.confirmService.confirm({
				message: `Are you sure you want to delete ${roleName} role?`,
			})
		) {
			await this.orgsBackend.deleteBranchRole(orgId, branchId, roleId);
			await this.getMyOrgs();
		}
	}

	async newOrgRole(orgId: string, email: string, role: 'owner' | 'admin') {
		await this.orgsBackend.putOrgRole(orgId, email, role);
		await this.getMyOrgs();
	}

	async newBranchRole(
		orgId: string,
		branchId: string,
		email: string,
		role: 'admin' | 'employee'
	) {
		await this.orgsBackend.putBranchRole(orgId, branchId, email, role);
		await this.getMyOrgs();
	}
}
